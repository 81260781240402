* {
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* from the other side */
* {
  padding: 0;
  margin: 0;
}
.navbar {
  position: fixed;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  padding: 30px;
  text-align: center;
  background-color: #fff;
  z-index: 99999;
  margin-bottom: 3rem;
}
.side {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  grid-column: span 4;
  padding: 5px;
  margin-top: 8rem;
  overflow-y: scroll;
}
.main {
  grid-column: span 8;
  padding: 20px;
  position: fixed;
  margin-left: 30rem;
  margin-top: 10rem;
}

.main-body {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

@media only screen and (min-width: 320px) {
  .main {
    margin-left: 7rem;
    margin-top: 1rem;
  }
  .side {
    margin-top: 4rem;
  }
  .navbar h1 {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 10px;
  }

  .sidebar-search {
    margin-bottom: -8px;
  }

  .siderbar-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1px;
  }
  .icon {
    font-size: 24px;
    cursor: pointer;
    text-align: right;
  }
  .hide {
    transform: translateX(-95px);
    transition: all 0.5s ease-in;
    margin-left: -2rem;
    display: grid;
    grid-column: span 0;
    background-color: none;
  }
  .show {
    transform: translateX(0px);
    transition: all 0.5s ease-in;
  }
  /* search */
  .search {
    display: inline-block;
    position: relative;
    margin: 1rem 0;
  }

  .search input[type="text"] {
    width: 83px;
    padding: 6px 7px;
    font-size: 9px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .search button[type="submit"] {
    background-color: #4e99e9;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 6.8px 15px;
    border-radius: 20px;
    font-size: 9px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.9s ease;
  }

  .search button[type="submit"]:hover {
    transform: scale(1.1);
    color: rgb(255, 255, 255);
    background-color: blue;
  }
  /* end /search */
  .contact_list ul {
    display: flex;
    flex-direction: column;
  }
  .contact_list ul li a {
    list-style-type: none;
    padding: 8px 6px;
    font-size: 9px;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #333;
    line-height: 3;
  }
  .contact_list ul li a:hover {
    background-color: #4e99e9;
    color: #fff;
    border-radius: 5px;
  }
  /* contact details */
  .contactDetail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;
  }
  .coctact-content {
    display: flex;
    gap: 10px;
  }
  .contact-description h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
    margin-bottom: 4px;
  }
  .contact-description p {
    font-size: 7px;
    margin-bottom: 3px;
    font-style: italic;
    font-weight: bold;
    color: #4e99e9;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .contact-description p:hover {
    color: blue;
  }
  .contact-details {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .img {
    width: 85px;
    height: 85px;
    border-radius: 60px;
  }
  /* button */
  .btn {
    position: relative;
    font-size: 8px;
    text-transform: capitalize;
    text-decoration: none;
    padding: 2px 8px;
    display: inline-block;
    border-radius: 6em;
    transition: all 0.2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;
  }

  .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  .btn::after {
    background-color: #fff;
  }

  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
  .btn2 {
    color: red;
  }
  .message {
    text-align: center;
    font-size: 12px;
    color: #757575;
  }
}
@media only screen and (min-width: 375px) {
  .main {
    margin-top: 1rem;
  }
  .search input[type="text"] {
    width: 98px;
    padding: 7px 9px;
    font-size: 11px;
  }
  .search button[type="submit"] {
    padding: 8.2px 19px;
  }
  .contact_list ul li a {
    padding: 7px 7px;
    font-size: 11px;
  }
  .img {
    width: 110px;
    height: 110px;
    border-radius: 60px;
  }
  .contact-description h3 {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .contact-description p {
    font-size: 9px;
    line-height: 1.2;
  }
  .btn {
    font-size: 9px;
    padding: 6px 13px;
  }
}
@media only screen and (min-width: 425px) {
  .main {
    margin-left: 9rem;
    margin-top: 1rem;
  }
  .side {
    margin-top: 4rem;
  }
  .navbar h1 {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 10px;
  }
  .search input[type="text"] {
    width: 113px;
    padding: 10px 10px;
    font-size: 9px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .search button[type="submit"] {
    background-color: #4e99e9;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 10px 21px;
    border-radius: 20px;
    font-size: 9px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.9s ease;
  }
  .contact_list ul li a {
    list-style-type: none;
    padding: 10px 9px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #333;
    line-height: 3;
  }
  .img {
    width: 130px;
    height: 130px;
    border-radius: 60px;
  }
  .contact-description h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    margin-bottom: 7px;
  }
  .contact-description p {
    font-size: 10px;
    line-height: 1.3;
  }
  .btn {
    font-size: 10px;
    padding: 5px 11px;
  }
}

@media only screen and (min-width: 640px) {
  .main {
    margin-left: 13rem;
    margin-top: 1rem;
  }
  .side {
    margin-top: 5rem;
  }
  .navbar h1 {
    text-transform: uppercase;
    font-size: 25px;
    letter-spacing: 16px;
  }
  .search input[type="text"] {
    width: 169px;
    padding: 11px 17px;
    font-size: 14px;
  }
  .search button[type="submit"] {
    padding: 11.9px 27px;
    border-radius: 20px;
    font-size: 12px;
  }
  .contact_list ul li a {
    padding: 10px 9px;
    font-size: 17px;
    line-height: 2.3;
  }
  .img {
    width: 200px;
    height: 200px;
    border-radius: 96px;
  }
  .contact-description h3 {
    font-size: 21px;
    margin-bottom: 9px;
  }
  .contact-description p {
    font-size: 16cidpx;
    line-height: 1.5;
  }
  .btn {
    font-size: 13px;
    padding: 7px 21px;
  }
  .coctact-content {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) {
  .main {
    margin-left: 17rem;
    margin-top: 3rem;
  }
  .side {
    margin-top: 6rem;
  }
  .navbar h1 {
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 16px;
  }
  .search input[type="text"] {
    width: 210px;
    padding: 14px 17px;
    font-size: 14px;
  }
  .search button[type="submit"] {
    padding: 13.9px 36px;
    font-size: 14px;
  }
  .contact_list ul li a {
    padding: 9px 9px;
    font-size: 19px;
    line-height: 2.3;
  }
  .img {
    width: 230px;
    height: 230px;
    border-radius: 108px;
  }
  .contact-description h3 {
    font-size: 24px;
    margin-bottom: 13px;
  }
  .contact-description p {
    font-size: 16px;
    line-height: 1.5;
  }
  .btn {
    font-size: 15px;
    padding: 10px 25px;
  }
  .coctact-content {
    align-items: center;
    display: flex;
    gap: 20px;
  }
}
@media only screen and (min-width: 900px) {
  .main {
    margin-left: 19rem;
    margin-top: 4rem;
  }
  .side {
    margin-top: 7rem;
  }
  .search input[type="text"] {
    width: 243px;
    padding: 15px 23px;
    font-size: 19px;
  }
  .search button[type="submit"] {
    padding: 16.6px 40px;
    font-size: 16px;
  }
  .contact_list ul li a {
    padding: 9px 9px;
    font-size: 23px;
    line-height: 2.1;
  }
  .img {
    width: 280px;
    height: 280px;
    border-radius: 139px;
  }
  .contact-description h3 {
    font-size: 33px;
    margin-bottom: 13px;
  }
  .contact-description p {
    font-size: 22px;
    line-height: 1.5;
  }
  .btn {
    font-size: 20px;
    padding: 10px 36px;
  }
  .contact-details {
    margin-top: 21px;
  }
  .navbar h1 {
    font-size: 46px;
  }
}
@media only screen and (min-width: 1024px) {
  .main {
    margin-left: 21rem;
    margin-top: 5rem;
  }
  .side {
    margin-top: 7rem;
  }
  .search input[type="text"] {
    width: 285px;
  }
  .contact_list ul li a {
    font-size: 36px;
    line-height: 1.7;
  }
  .img {
    width: 350px;
    height: 350px;
    border-radius: 177px;
  }
  .contact-description h3 {
    font-size: 35px;
    margin-bottom: 13px;
  }
  .contact-description p {
    font-size: 25px;
    line-height: 1.5;
  }
  .btn {
    font-size: 21px;
    padding: 10px 40px;
  }
  .contact-details {
    margin-top: 53px;
  }

  .navbar h1 {
    font-size: 56px;
  }
}
@media only screen and (min-width: 1280px) {
  .main {
    margin-left: 31rem;
    margin-top: 6rem;
  }
  .side {
    margin-top: 7rem;
  }
  .search input[type="text"] {
    width: 369px;
    padding: 22px 24px;
    font-size: 23px;
  }
  .search button[type="submit"] {
    padding: 23px 55px;
    font-size: 22px;
  }
  .contact_list ul li a {
    font-size: 45px;
    line-height: 1.7;
  }
  .img {
    width: 410px;
    height: 410px;
    border-radius: 207px;
  }
  .contact-description h3 {
    font-size: 47px;
    margin-bottom: 13px;
  }
  .contact-description p {
    font-size: 29px;
    line-height: 1.5;
  }
  .btn {
    font-size: 25px;
    padding: 16px 54px;
  }
}
